import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./index.css";

import {
  Button,
  Form,
  Input,
  Layout,
  Typography,
  message,
  InputNumber,
  Dropdown,
  Space,
  Select,
  Radio,
  notification,
} from "antd";
import { useFirestore } from "react-redux-firebase";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { ref } from "firebase/database";
import { GeoFire } from "geofire";
import { db, rtdb } from "../../../src/app/config/develop";
import Item from "antd/lib/list/Item";

import firebase from "firebase/compat/app";
import axios from "axios";

const { Text } = Typography;
const { TextArea } = Input;
/**
 * James Tuttle | Date: 2021-09-09:Time: 11:00:00 =>adding jobName to the
 * Form on UI and to the database (Firestore Collection).
 */

const Home = () => {
  const firestore = useFirestore();
  const geoFire = new GeoFire(ref(rtdb, "company-locations"));

  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const [jobServiceSeed, setJobServiceSeed] = useState([]);
  const [loadings, setLoadings] = useState(false);

  const convertAddressToGeolocation = async (address) => {
    // Use a geocoding service (such as Google Maps API) to convert the address to coordinates
    // Replace the API_KEY with your own Google Maps API key
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address,
    )}&key=${"AIzaSyCJ2UeIomotx5mOcJONzRMFG7H-ZThpT1c"}`;

    // Make an HTTP request to the geocoding service
    const response = await axios.get(apiUrl);
    const { results } = response.data;
    if (results.length > 0) {
      // Extract the latitude and longitude from the geocoding response
      const { lat, lng } = results[0].geometry.location;
      return [lat, lng];
    }

    return null;
  };

  const redirectToStripe = ({ uid }) => {
    // alert('yes it work !!')
    firestore
      .collection("companies")
      .doc(uid)
      .onSnapshot((querySnapshot) => {
        console.log(querySnapshot.data());
        const source = querySnapshot.data();
        const { connectId } = source;
        const { url } = connectId;
        console.log(url);
        window.location = url;
      });
  };

  // useEffect to get data from firestore for jobName dropdown
  // from the 'job-service' collection.
  useEffect(() => {
    fetchJobServiceNames();
  }, []);

  // fetch job-service collection for the jobName field only
  // and sort by jobName in ascending order.
  const fetchJobServiceNames = () => {
    firestore
      .collection("job-service")
      .orderBy("jobName", "asc")
      .onSnapshot((querySnapshot) => {
        let jobServices = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const job = {
            // id: data.uid,
            item: data.jobName,
          };
          // console.log(`job service querySnapShot >>>>>> ${data.jobName}}`)
          jobServices.push(job);
        });

        setJobServiceSeed(jobServices);
      });
  };

  // Set delay after form submit then push to companies list page.
  const changeFormSubmitSuccess = () => {
    setTimeout(() => {
      setSubmitted(true);
      // Send to '/products' page
      history.push("/products");
    }, 0);
  };

  const validateForm = async (data) => {
    // Get the form data from the client-side
    const { businessAddress, email, phone } = data;

    // Query the Firestore "companies" collection to check if the data exists
    const snapshot = await firestore
      .collection("companies")
      .where("businessAddress", "==", businessAddress)
      .where("email", "==", email)
      .where("phone", "==", phone)
      .get();

    // If the query returns any results, the data already exists in the database
    if (!snapshot.empty) {
      return false;
    }

    // Otherwise, the data is valid
    return true;
  };

  /**************************************************************************
   * ------------------------------------------------------------------------
   * @details onFinish() is called when the form is submitted and validated.
   * writes out to firebase and calls cloud functions to create a stripe connect
   * account.
   * ------------------------------------------------------------------------
   ***************************************************************************/
  const onFinish = async (values) => {
    setLoadings(true);

    const { getFieldValue } = form;
    const initialServicePayment = parseInt(
      getFieldValue("initialServicePayment"),
    );
    const email = getFieldValue("email");
    const businessAddress = getFieldValue("businessAddress");
    const phone = getFieldValue("phone");
    const result = await validateForm({ businessAddress, email, phone });
    const latlng = await convertAddressToGeolocation(businessAddress);

    if (result === false) {
      api.info({
        message: ``,
        description: "Company already exist.",
        placement: "topRight",
      });
      setLoadings(false);
      return;
    }

    const res = await axios.get("https://api.ipify.org?format=json");
    const newItem = await addDoc(collection(db, "companies"), {
      ...values,
      tos_acceptance: {
        date: Date.now(),
        ip: res.data.ip,
      },
    });

    // get uid from created doc and add to record as its own uid [pk]
    const fileId = newItem.id;

    console.log("Saving location:", latlng);

    // Here GeoFire
    if (latlng) {
      try {
        await geoFire.set(fileId, latlng);
      } catch (err) {
        console.log("Saving location to geofire is failed: ", err);
      }
    }

    // console.log("added fileID: ", fileId);
    const updateDocId = doc(db, "companies", fileId);
    await updateDoc(updateDocId, {
      uid: fileId,
    });
    console.log(`>>>>> Show Address Results ${values.showAddress}`);

    /**
     * .. START STRIPE CONNECT ACCOUNT CREATION --------------------------
     *.. Creates Stripe account & adds the Stripe Id to firestore doc's connectId field
     */
    const dt = parseInt(Date.now() / 1000);
    const stripeId = await firebase
      .functions()
      .httpsCallable("createStripeConnectAccount")({
      ...values,
      tos_acceptance: {
        date: dt,
        ip: res.data.ip,
      },
    });

    const updateDocStripeId = doc(db, "companies", fileId);

    await updateDoc(updateDocStripeId, {
      connectId: stripeId.data,
    });

    let stripURL = stripeId.data.url;

    const decode = decodeURIComponent(stripURL);

    // Split the search string on the '&' character
    const queryStrings = decode.split("/").map((x) => x);
    const accountId = queryStrings[5];

    await updateDoc(updateDocStripeId, {
      accountId,
    });

    redirectToStripe({
      uid: fileId,
    });
    // On success, set submitted to true for user message on success.
    changeFormSubmitSuccess();
    // Clear all fields on from for next entry.
    form.resetFields();

    setLoadings(false);
  };
  // .. END STRIPE CONNECT ACCOUNT CREATION ----------------------------------

  /**
   *.. START CUSTOM STYLES --------------------------------------------------
   */
  // for input text box style
  const inputStyle = {
    width: "100%",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #36096D",
    padding: "0 0 0 5px",
    fontSize: "1rem",
    color: "#36096D",
    outline: "none",
    caretColor: "#36096D",
  };

  // for number style input
  const inputNumberStyle = {
    width: "50%",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #36096D",
    padding: "0 0 0 5px",
    fontSize: "1rem",
    color: "#36096D",
    caretColor: "#36096D",
  };

  // for textarea input
  const textareaStyle = {
    width: "100%",
    height: "100px",
    borderRadius: "5px",
    border: "1px solid #36096D !important",
    padding: "0 0 0 5px",
    fontSize: "1rem",
    color: "#36096D",
    caretColor: "#36096D",
  };

  // info in dropdown menu
  const optionStyle = {
    width: "100%",
    height: "35px",
    borderBottom: "1px solid #36096D",
    borderLeft: "1px solid #36096D",
    borderRadius: "5px",
    padding: "0 0 0 5px",
    fontSize: "1rem",
    color: "#36096D",
    caretColor: "#36096D",
  };

  // for Select box style
  const selectStyle = {
    width: "100%",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #36096D !important",
    padding: "0 0 0 5px",
    fontSize: "1rem",
    color: "#36096D",
    outline: "none",
    caretColor: "#36096D",
  };
  //.. --------- END CUSTOM STYLES --------------------------------------------

  if (submitted) {
    return (
      <>
        <div
          style={{
            margin: "10% 0px 0px 10%",
            padding: "5rem 0 0 0",
            minWidth: "100vh",
            minHeight: "100vh",
            backgroundColor: "#FFFFFF",
          }}
        >
          <h1
            style={{
              color: "#36096D",
              font: "bold",
              fontSize: "2rem",
              margin: "0 0 0 0 ",
            }}
          >
            SUCCESS
          </h1>
          <h4>Sending Company List Page...</h4>
        </div>
      </>
    );
  }

  return (
    <Layout
      style={{
        margin: "0px 0px 0px 0px",
        padding: "2em",
        minWidth: "100vh",
        minHeight: "100vh",
        backgroundColor: "#FFFFFF",
      }}
    >
      {contextHolder}
      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          color: "#36096D",
          font: "bold",
          fontStyle: "italic",
          margin: "0 30% 0 0",
          textShadow: "0px 0px 6px #36096d7a",
        }}
      >
        Add a Company
      </h2>
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        name="nest-messages"
        initialValues={{
          additionalServiceDescription: "",
          additionalServiceCost: 0,
          initialServicePayment: 0,
        }}
        onFinish={onFinish}
        style={{
          marginTop: "16px",
          width: "50%",
          overflowX: "hidden",
          boxShadow: "0px 0px 10px 0px rgba(3px,0,0,0.25)",
          borderRadius: "10px",
          minWidth: "75%",
          padding: "2em",
        }}
      >
        <Form.Item
          name={["businessName"]}
          label="Company Name"
          rules={[
            { required: true, message: "Please enter a company name" },
            { whitespace: true, message: "Please enter a company name" },
            { min: 3, message: "Company name must be at least 3 characters" },
          ]}
          hasFeedback
        >
          <Input style={inputStyle} placeholder="Your Company Name" />
        </Form.Item>

        <Form.Item
          name={["businessAddress"]}
          label="Company Full Address"
          rules={[
            { required: true, message: "Please enter a company address" },
            { whitespace: true, message: "Please enter a company address" },
            {
              min: 10,
              message: "Company address must be at least 10 characters",
            },
          ]}
          hasFeedback
        >
          <Input
            style={inputStyle}
            placeholder="123 Main St, City, State, Zip"
          />
        </Form.Item>

        {/* //! showAddress boolean */}
        <Form.Item
          name={["showAddress"]}
          label="Show Address to Customers?"
          rules={[{ required: true, message: "Please select an option" }]}
          hasFeedback
        >
          <Radio.Group
            style={{
              display: "flex",
              borderRadius: "15px",
              marginLeft: "10px",
            }}
            initialValues={true}
            optionType="button"
            buttonStyle="solid"
            hasFeedback
          >
            <Radio.Button value={true}>Yes</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name={["jobName"]}
          label="Company Service Name"
          rules={[
            {
              required: true,
              message: "Please select your company's service name",
            },
          ]}
        >
          <Select
            style={inputStyle}
            placeholder="Please select your company's service name"
          >
            {/*  onChange={(e) => dropDownSelected} */}
            {jobServiceSeed.map(({ item, id }) => (
              <Select.Option key={id} value={item} style={optionStyle}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={["email"]}
          label="E-mail"
          rules={[
            {
              required: true,
              message: "Please enter a company email - user@company.com",
            },
            {
              type: "email",
              message: "Please enter a valid email - user@company.com",
            },
          ]}
          hasFeedback
        >
          <Input style={inputStyle} placeholder="user@company.com" />
        </Form.Item>

        <Form.Item
          name={["phone"]}
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter a company phone number - 123-123-1234",
            },
            {
              type: "string",
              message: "Please enter a valid phone number - 123-123-1234",
            },
            {
              min: 12,
              max: 13,
              message:
                "Phone number must be at least 12 characters and no more than 15",
            },
          ]}
          hasFeedback
        >
          <Input style={inputStyle} placeholder="123-123-1234" />
        </Form.Item>

        <Form.Item
          name={["startingFeeDescription"]}
          label="Initial Hours of Service"
          rules={[
            {
              required: true,
              message: "Please enter a starting service hours description",
            },
            {
              whitespace: true,
              message: "Please enter a starting service hours description",
            },
            {
              min: 5,
              message:
                "Starting service hours description must be at least 5 characters",
            },
          ]}
          hasFeedback
        >
          <Input style={inputStyle} placeholder="E.g., First Two Hours" />
        </Form.Item>

        <Form.Item
          name={["initialServicePayment"]}
          label="Base Service Fee - Enter the amount in pennies. E.g., 15000 pennies = $150.00"
          rules={[
            {
              required: true,
              message: "Please enter a starting price",
              pattern: new RegExp(/^[0-9]*$/),
              min: 1,
            },
          ]}
          hasFeedback
          style={{
            width: "50%",
          }}
        >
          <InputNumber style={inputStyle} placeholder="" type="number" />
        </Form.Item>

        <Form.Item
          name={["additionalServiceDescription"]}
          label="Additional Hours (Optional)"
          rules={[{ required: false }]}
        >
          <Input
            style={inputStyle}
            placeholder="Specify additional hours if applicable. E.g., 'Next Three Hours'"
          />
        </Form.Item>

        <Form.Item
          name={["additionalServiceCost"]}
          label="Additional Fee (Optional) - Enter the additional fee in pennies. E.g., 7500 pennies = $75.00"
          style={{
            width: "50%",
          }}
        >
          <InputNumber type="number" style={inputStyle} placeholder="" />
        </Form.Item>

        <Form.Item
          name={["jobDescription"]}
          label="Company Job Description"
          rules={[
            {
              required: true,
              message: "Please enter your company's job description",
            },
            // { type: "string", message: "Please enter a valid job description" },
            {
              min: 35,
              message: "Job description must be at least 35 characters",
            },
          ]}
          hasFeedback
        >
          <TextArea
            rows={10}
            style={textareaStyle}
            placeholder="Please describe the services that your company offers to customers"
          />
        </Form.Item>

        <Form.Item>
          <Button
            style={{
              margin: ".6em 0em 0em",
              backgroundImage: "linear-gradient(to right, #36096D, #a789ccea)",
              borderColor: "#36096D",
              borderRadius: "5px",
              font: "bold",
              "&:hover": {
                backgroundColor: "#dac9f0",
              },
            }}
            type="primary"
            loading={loadings}
            size="large"
            htmlType="submit"
            block
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

export default Home;
