import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { asyncStarted, asyncFinished } from "../async/asyncSlice";

export const initialState = {
  isAuthenticated: false,
  isVerifying: false,
  userData: null,
  userType: null,
  fromLogout: false,
  resetPasswordComplete: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticateUser: (state) => {
      state.isAuthenticated = true;
    },
    unAuthenticateUser: (state) => {
      state.isAuthenticated = false;
      state.userData = null;
      state.userType = null;
    },
    verifyRequest: (state) => {
      state.isVerifying = true;
    },
    verifyComplete: (state) => {
      state.isVerifying = false;
    },
    getUserData: (state, { payload }) => {
      state.userData = payload;
      state.userType = payload.userType;
    },
    getFromLogout: (state) => {
      state.fromLogout = true;
    },
  },
});

export const {
  authenticateUser,
  unAuthenticateUser,
  verifyRequest,
  verifyComplete,
  getUserData,
  getFromLogout,
  verifyPasswordResetComplete,
} = authSlice.actions;

export const authSelector = (state) => state.auth;

export default authSlice.reducer;

export const login =
  ({ firebase, firestore }, user, history) =>
  async (dispatch) => {
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(user.email, user.password);
      message.error("Login Successful", 4);
    } catch (error) {
      console.log(error);
      dispatch(asyncFinished());
      message.error("Username or password is incorrect", 4);
    }
  };

export const logout =
  ({ firebase }, history) =>
  async (dispatch) => {
    dispatch(asyncStarted());
    await firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(unAuthenticateUser());
      });
    dispatch(asyncFinished());
    dispatch(getFromLogout());
    history.push("login");
  };
