import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/functions";

const config = require(`./${process.env.REACT_APP_ENVIRONMENT}`).firebaseConfig;

firebase.initializeApp(config);
firebase.firestore();

export default firebase;
