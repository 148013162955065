import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PATH_LOGIN } from "./routesConstants";

export const ProtectedRoute = ({
  component: Component,
  authed,
  path,
  ...rest
}) => (
  <Route
    path={path}
    {...rest}
    render={(props) =>
      authed === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: PATH_LOGIN, state: { from: props.location } }}
          // to={{
          //   pathname: "/",
          //   state: {
          //     prevLocation: path,
          //     error: "You need yo login first!",
          //   },
          // }}
        />
      )
    }
  />
);
