import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "../../reducer";
import { actionTypes } from "react-redux-firebase";

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    // serializableCheck ignores warning from Firebase Login / Redux Toolkit
    serializableCheck: {
      ignoredActions: [actionTypes.LOGIN],
    },
    // Only in dev. Can turn on if needed
    immutableCheck: false,
  }),
});
