// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCJ2UeIomotx5mOcJONzRMFG7H-ZThpT1c",
  authDomain: "skedaddle-2f649.firebaseapp.com",
  databaseURL: "https://skedaddle-2f649.firebaseio.com",
  projectId: "skedaddle-2f649",
  storageBucket: "skedaddle-2f649.appspot.com",
  messagingSenderId: "146413610587",
  appId: "1:146413610587:web:5bdd40a7df101d2bc1817e",
  measurementId: "G-ZXW7CXSSKF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

// Init Firestore
// ({ ignoreUndefinedProperties: true })
export const db = getFirestore(app);

// Init Firebase RTDB
export const rtdb = getDatabase(app);
