import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Dropdown,
  Menu,
  Divider,
  PageHeader,
  Space,
  Modal,
  Typography,
} from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useFirebase } from "react-redux-firebase";
import { useDispatch } from "react-redux";
import { logout } from "../login/AuthSlice";
import "./TopBar.css";

const { confirm } = Modal;
const { Text } = Typography;

const TopBar = ({ history }) => {
  const firebase = useFirebase();
  const dispatch = useDispatch();

  useEffect(() => {
    const subscribeAuth = firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
      }
    });

    return () => {
      subscribeAuth();
    };
  }, [firebase]);

  const renderLogoutBtn = () => {
    return (
      <Button
        key="profile"
        type="link"
        style={{ backgroundColor: "#f0f4ff", borderRadius: "5px" }}
        icon={<UserOutlined style={{ color: "#36096D" }} />}
      >
        <Text style={{ color: "#36096D" }}>Logout</Text>
      </Button>
    );
  };

  const handleLogout = () => {
    dispatch(logout({ firebase }, history));
  };

  const showLogoutModal = () => {
    confirm({
      icon: <ExclamationCircleFilled style={{ color: "#ff4d4f" }} />,
      content: "Logout?",
      maskClosable: true,
      onOk() {
        handleLogout();
      },
      onCancel() {},
    });
  };

  const renderAppName = () => {
    return (
      <div
        style={{
          marginLeft: "6px",
          backgroundColor: "#36096D",
          borderRadius: "5px",
          paddingLeft: "16px",
          paddingRight: "16px",
          backgroundColor: "transparent",
        }}
      >
        <div style={{ width: 100, height: 37, overflow: "hidden" }}>
          <img
            src="/assets/skedaddle_admin_logo.png"
            alt="logo"
            style={{
              width: 100,
              marginTop: "-30px",
            }}
          />
        </div>
        {/* <Text style={{ color: "#FFFFFF", fontSize: "16px" }}>
          <span role="img" aria-labelledby="hammmer wrench crossed" style={{fontSize:"30px"}}>🛠</span> Skedaddle Admin
        </Text> */}
      </div>
    );
  };

  return (
    <Fragment>
      <PageHeader
        style={{
          backgroundImage: "linear-gradient(to right, #36096D, #a788cdc5)",
        }}
        title={renderAppName()}
        className="TopBar"
        extra={[
          <Space key="headerSpacing" size={16}>
            <Divider type="vertical" style={{ opacity: ".25" }} />
            <Dropdown
              overlay={
                <Menu style={{ width: "200px" }}>
                  <Menu.Item danger onClick={handleLogout}>
                    <LogoutOutlined />
                    Logout
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              {renderLogoutBtn()}
            </Dropdown>
          </Space>,
        ]}
      />
    </Fragment>
  );
};

export default withRouter(TopBar);
