import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  asyncStart: false,
  asyncFinish: true,
  asyncError: false,
};

const asyncSlice = createSlice({
  name: "async",
  initialState,
  reducers: {
    /* From documentation: Insert reducers an object, where the keys will become action type strings,
		and the functions are reducers that will be run when that action type is dispatched.*/

    asyncActionStart(state) {
      state.asyncStart = true;
      state.asyncFinish = false;
      state.asyncError = false;
    },

    asyncActionFinish(state) {
      state.asyncStart = false;
      state.asyncFinish = true;
      state.asyncError = false;
    },

    asyncActionError(state) {
      state.asyncStart = false;
      state.asyncFinish = false;
      state.asyncError = true;
    },
  },
});

// Export keys as actions
export const { asyncActionStart, asyncActionFinish, asyncActionError } =
  asyncSlice.actions;

// Export selector as name from slice
export const asyncSelector = (state) => state.async;

// Default export for reducer to get referenced in root reducer
export default asyncSlice.reducer;

// Thunk actions
export const asyncStarted = () => {
  return async (dispatch) => {
    await dispatch(asyncActionStart());
  };
};

export const asyncFinished = () => {
  return async (dispatch) => {
    await dispatch(asyncActionFinish());
  };
};
export const asyncHasError = () => {
  return async (dispatch) => {
    await dispatch(asyncActionError());
  };
};
