import React, { useState, useEffect } from "react";
import { Table, Popconfirm, Button } from "antd";

import "./index.css";

import { Layout } from "antd";

import { useFirestore } from "react-redux-firebase";

/**
 *
 *  James Tuttle | Date: 2021-09-09:Time: 11:00:00 =>adding jobName to the table.
 */

const ProductsList = () => {
  const firestore = useFirestore();
  const [signals, setSignals] = useState([]);

  // useEffect() is kind of like ViewDidLoad for a UIViewController.
  useEffect(() => {
    fetchServiceDetails();
  }, []);

  const fetchServiceDetails = () => {
    firestore.collection("companies").onSnapshot((querySnapshot) => {
      var companies = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const company = {
          id: doc.id,
          businessName: data.businessName,
          jobName: data.jobName,
          businessAddress: data.businessAddress,
          email: data.email,
          phone: data.phone,
          showAddress: data.showAddress,
          startingFeeDescription: data.startingFeeDescription,
          initialServicePayment: data.initialServicePayment,
          additionalServiceDescription: data.additionalServiceDescription,
          additionalServiceCost: data.additionalServiceCost,
          jobDescription: data.jobDescription,
        };
        // console.log(`companies >>>>>> ${company.showAddress}`)
        companies.push(company);
      });

      setSignals(companies);
    });
  };

  // Create the columns to be displayed in the company table.

  const columns = [
    {
      title: "Business Name",
      dataIndex: "businessName",
      editable: false,
    },
    {
      title: "Job Name",
      dataIndex: "jobName",
      editable: false,
    },
    {
      title: "Business Address",
      dataIndex: "businessAddress",
      editable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      editable: true,
    },
    {
      title: "Show Business Address?",
      dataIndex: "showAddress",
      editable: false,
      key: "showAddress",
      dataIndex: "showAddress",
      render: (text) => <>{text === true ? "Yes" : "No"}</>,
    },
    {
      title: "Starting Price Description",
      dataIndex: "startingFeeDescription",
      editable: true,
    },
    {
      title: "Starting Price Amount",
      dataIndex: "initialServicePayment",
      editable: true,
    },
    {
      title: "Additional Service Description",
      dataIndex: "additionalServiceDescription",
      editable: true,
    },
    {
      title: "Additional Service Price Amount",
      dataIndex: "additionalServiceCost",
      editable: true,
    },
    {
      title: "Job Desription",
      dataIndex: "jobDescription",
      editable: true,
    },
    {
      title: "Delete",
      render: (record) => (
        <Popconfirm
          title="Confirm Delete?"
          onConfirm={() => handleDelete(record.id)}
        >
          <a>Delete</a>
        </Popconfirm>
      ),
    },
  ];

  // Delete a company package.
  const handleDelete = (key) => {
    firestore
      .collection("companies")
      .doc(key)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  };

  /**
   *
   *! === NO LONGER NEEDED ON PROD. ===
   *
   */
  // Populate with demo data.
  // const populateData = () => {
  //   var batch = firestore.batch();

  //   demoData.forEach((company) => {
  //     console.log(company.title)
  //     const ref = firestore.collection("companies").doc();
  //     batch.set(ref, company);
  //   });

  //   // Commit the batch
  //   batch.commit().then(function () {
  //     console.log('Demo Data Added')
  //   });
  // }

  return (
    <Layout
      style={{
        margin: "0px 0px 0px 0px",
        padding: "2em",
        minWidth: "100vh",
        minHeight: "100vh",
        backgroundColor: "#ffffff",
        overflowY: "hidden",
      }}
    >
      {/* Button no longer needed in production */}
      {/* <Button
        onClick={populateData}
        style={{
          width: "200px",
          height: "auto",
          marginBottom: "10px",
          backgroundColor: "#36096D",
          borderColor: "#36096D"
        }}
        type="primary"
      >
        Populate Data
      </Button> */}
      <div>
        <Table
          rowKey="id"
          bordered
          dataSource={signals}
          columns={columns}
          style={{
            overflow: "scroll",
            boxShadow: "0px 0px 10px 5px #00000015",
            borderRadius: "10px",
          }}
        />
      </div>
    </Layout>
  );
};

export default ProductsList;
