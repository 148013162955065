import React from "react";
import { Menu } from "antd";
import {
  PlusCircleFilled,
  ShopOutlined,
  // UnorderedListOutlined
} from "@ant-design/icons";

import "./SideNav.css";
import { NavLink } from "react-router-dom";
import { PATH_HOME, PATH_PRODUCTS } from "../../app/routes/routesConstants";

const SideNav = () => {
  return (
    <Menu
      defaultSelectedKeys={PATH_HOME}
      mode="inline"
      className="Sidebar"
      style={{
        width: "100px",
        backgroundColor: "#36096D",
        color: "transparent",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Menu.Item
          key={"products"}
          title="Company"
          style={{
            backgroundColor: "#36096D",
            padding: "0",
            paddingLeft: ".4rem",
          }}
        >
          <NavLink activeClassName="active" to={PATH_PRODUCTS}>
            <ShopOutlined style={{ color: "white" }} />
          </NavLink>
        </Menu.Item>

        <span
          style={{
            paddingTop: "1.5rem",
            paddingLeft: ".5rem",
            color: "white",
            fontSize: ".6rem",
          }}
        >
          Companies
        </span>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <Menu.Item
          key={PATH_HOME}
          title="Add Company"
          style={{
            backgroundColor: "#36096D",
            padding: "0",
            paddingLeft: ".2rem",
          }}
        >
          <NavLink activeClassName="active" to={PATH_HOME}>
            <PlusCircleFilled
              style={{
                fill: "white",
                color: "white",
                backgroundColor: "#36096D",
                width: "20px",
                height: "50px",
              }}
            />
          </NavLink>
        </Menu.Item>

        <span
          style={{
            paddingTop: "1.5rem",
            paddingLeft: ".2rem",
            color: "white",
            fontSize: ".6rem",
          }}
        >
          Add Company
        </span>
      </div>
    </Menu>
  );
};

export default SideNav;
