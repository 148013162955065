import React, { Fragment } from "react";
import { Layout } from "antd";
import { PATH_HOME, PATH_PRODUCTS } from "./app/routes/routesConstants";

import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import ProductsList from "./pages/home/ProductsList";

import { ProtectedRoute } from "./app/routes/ProtectedRoute";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import SideNav from "./pages/sideNav/SideNav";
import TopBar from "./pages/topBar/TopBar";

const { Header, Content, Sider } = Layout;

function App() {
  const firebase = useFirebase();

  return (
    <Fragment>
      <Switch>
        <Route exact path={"/login"} component={Login} />

        <Route
          render={() => (
            <Fragment>
              <Layout>
                <Header style={{ lineHeight: "56px", height: "56px" }}>
                  <TopBar />
                </Header>

                <Layout>
                  <Sider collapsed={true}>
                    <SideNav />
                  </Sider>

                  <Content
                    style={{
                      margin: "0px 0px 0px 0px",
                      minWidth: "100vh",
                      minHeight: "100vh",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <ProtectedRoute
                      authed={firebase.auth().currentUser !== null}
                      exact
                      path={PATH_PRODUCTS}
                      component={ProductsList}
                      key="products"
                    />

                    <ProtectedRoute
                      authed={firebase.auth().currentUser !== null}
                      exact
                      path={PATH_HOME}
                      component={Home}
                      key="home"
                    />
                  </Content>
                </Layout>
              </Layout>
            </Fragment>
          )}
        />
      </Switch>
    </Fragment>
  );
}

export default withRouter(App);
