import React, { useCallback, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  message,
} from "antd";
import "antd/dist/antd.min.css";

import { useFirebase } from "react-redux-firebase";
import { PATH_HOME, PATH_PRODUCTS } from "../../app/routes/routesConstants";

const { Title } = Typography;
const { Content } = Layout;

const Login = ({ history }) => {
  const firebase = useFirebase();

  useEffect(() => {
    const subscribeAuth = firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        history.push(PATH_PRODUCTS);
      }
    });

    return () => {
      subscribeAuth();
    };
  });

  // for input text box style
  const inputStyle = {
    width: "100%",
    height: "35px",
    borderRadius: "5px",
    border: "1px solid #36096D",
    fontSize: "1rem",
    color: "#36096D",
    outline: "none",
    caretColor: "#36096D",
  };

  const onFinish = useCallback((data) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .catch((error) => {
        console.log(error);
        message.error("Username or password is incorrect", 4);
      });
  });

  return (
    <Content>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={10}
          xl={10}
          style={{ height: "100vh", backgroundColor: "#FFFFFF" }}
        >
          <div
            style={{
              padding: "2em 4em",
              display: "block",
              position: "relative",
              top: "50%",
              transform: "translateY(-55%)",
            }}
          >
            <div>
              <span
                role="img"
                aria-labelledby="hammmer wrench crossed"
                style={{
                  fontSize: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                🛠
              </span>
            </div>
            <div>
              <h2
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#36096D",
                }}
              >
                Admin Login
              </h2>
            </div>
            <Form
              layout="vertical"
              style={{ marginTop: "4em" }}
              hideRequiredMark
              onFinish={onFinish}
            >
              <Form.Item name="email" label="">
                <Input
                  size="large"
                  placeholder="email"
                  // style={{ height: "40px" }}
                  style={inputStyle}
                />
              </Form.Item>

              <Form.Item
                name="password"
                label=""
                rules={[
                  {
                    required: true,
                    message: "Password required",
                  },
                ]}
              >
                <Input.Password
                  placeholder="password"
                  size="'large"
                  // style={{ height: "40px" }}
                  style={inputStyle}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    margin: ".6em 0em 0em",
                    backgroundColor: "#36096D",
                    backgroundImage:
                      "linear-gradient(to right, #36096D, #a788cdc5)",
                    borderRadius: "5px",
                  }}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                >
                  Sign In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
        {/* <Col md={14} lg={14} xl={14} style={{ backgroundColor: "#4D0D9C" }}> */}
        <Col
          md={14}
          lg={14}
          xl={14}
          style={{
            backgroundImage: "linear-gradient(to right, #36096D, #8d56d1c3)",
          }}
        >
          <div
            style={{
              padding: "4em 6em",
              display: "block",
              minWidth: "200",
              textAlign: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src="/assets/app_logo_shadow.png"
                alt="logo"
                style={{
                  width: "65%",
                  height: "auto",
                  marginTop: "",
                }}
              />
            </div>

            <Title style={{ color: "#FFFFFF", lineHeight: "55px" }} level={1}>
              Set your company's description and prices for your customers with
              Skedaddle Admin
            </Title>
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default Login;
